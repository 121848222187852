import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TAllCustomers,
  TCashObj,
  TClickDetails,
  TClickReports,
  TmPowerCProductData,
  TOrgProduct,
  TTradeDetailsObj,
  TTradeObj,
} from "../types/customers";
import { MarketDetails, TraderStatus } from "../types";

type TinitialState = {
  clickResponse: TClickDetails | null;
  tradeResponse: TTradeDetailsObj | null;
  cashResponse: TCashObj | null;
  clickReports: TClickReports | null;
  clickFarmerReports: TClickReports | null;
  selectedTrade: TTradeObj | null;
  refreshCustomerEntity: boolean;
  markets: MarketDetails[];
  traderStatus: TraderStatus[];
  refreshBorrowerEntity: boolean;
  selectedMosUser: TmPowerCProductData | null;
  activeEnityTab: TOrgProduct | null;
};
const initialState: TinitialState = {
  clickResponse: null,
  tradeResponse: null,
  cashResponse: null,
  clickReports: null,
  clickFarmerReports: null,
  selectedTrade: null,
  refreshCustomerEntity: false,
  markets: [],
  traderStatus: [],
  refreshBorrowerEntity: false,
  selectedMosUser: null,
  activeEnityTab: null,
};
const customerSlice = createSlice({
  name: "customers",
  initialState: initialState,
  reducers: {
    setClickResponse: (state, action) => {
      state.clickResponse = action.payload || null;
    },
    setTradeResponse: (state, action) => {
      state.tradeResponse = action.payload || null;
    },
    setCashResponse: (state, action) => {
      state.cashResponse = action.payload || null;
    },
    setClickReportsResponse: (state, action) => {
      state.clickReports = action.payload || null;
    },
    setClickFarmerReportsResponse: (state, action) => {
      state.clickFarmerReports = action.payload || null;
    },
    setRefreshCustomerEntity: (state) => {
      state.refreshCustomerEntity = !state.refreshCustomerEntity;
    },
    setMarketDetails: (state, action) => {
      state.markets = action.payload;
    },
    setTraderStatus: (state, action) => {
      state.traderStatus = action.payload;
    },
    showTradeLedgerSummary: (
      state,
      action: PayloadAction<{
        trade: TTradeObj | null;
      }>
    ) => {
      state.selectedTrade = action.payload.trade || null;
    },
    setRefreshBorrowerEntity: (state) => {
      state.refreshBorrowerEntity = !state.refreshBorrowerEntity;
    },
    setSeletedMosUser: (state, action) => {
      state.selectedMosUser = action.payload;
    },
    setActiveEntityTab: (state, action) => {
      state.activeEnityTab = action.payload;
    },
  },
});

export const {
  setClickResponse,
  setTradeResponse,
  setCashResponse,
  setClickReportsResponse,
  setClickFarmerReportsResponse,
  showTradeLedgerSummary,
  setRefreshCustomerEntity,
  setMarketDetails,
  setTraderStatus,
  setRefreshBorrowerEntity,
  setSeletedMosUser,
  setActiveEntityTab,
} = customerSlice.actions;
export default customerSlice.reducer;
