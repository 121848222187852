import { useContext, useState } from "react";
import { deviceInfoContext } from "../../context";
import { Input, Button } from "../../components/ui";
import PageHeader from "../../components/commons/page-header";
import useLogin from "./useLogin";
import Illustration from "../../assets/images/Illustration.svg";
import login_bg from "../../assets/images/login_bg.png";
import mpower from "../../assets/images/mpower.svg";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
const Login = () => {
  const { width } = useContext(deviceInfoContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { onLogin } = useLogin();
  const [showPassword, setShowPassword] = useState(false);
  return (
    <>
      {/* <PageHeader showTitle={true} title="Account Login" topVal="0" /> */}
      <div className="flex h-full" style={{ height: "100vh" }}>
        <div
          className="w-50 relative"
          style={{
            backgroundImage: `url(${login_bg})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div
            className="color_white absolute"
            style={{
              left: "40%",
              bottom: "25%",
              transform: "translate(-40%, -25%)",
            }}
          >
            <p className=" text-3xl font-bold">Admin Portal</p>
            <p className=" text-base">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the Lorem Ipsum is simply dummy
              text of the printing and typesetting industry. Lorem Ipsum has
              been the{" "}
            </p>
          </div>
        </div>
        <div className="w-50 relative flex justify-center items-center screen_bg">
          <div className="px-3 flex justify-center items-center">
            <div className="border br-10 bg-white ">
              <div className="w-full py-6 px-[60px] border-b">
                {" "}
                <img src={mpower} className="" />
              </div>
              <div className="py-8 px-[60px]">
                <p className="text-base font-bold mb-6">Account Login</p>
                <div className="flex gap-5 flex-col">
                  <div className="grow-[2]">
                    <Input
                      label="Email ID *"
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                  </div>

                  <div className="grow-[2] relative">
                    <Input
                      label="Password *"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-10 color-sub-text"
                    >
                      {showPassword ? (
                        <FaEyeSlash size={18} />
                      ) : (
                        <FaEye size={18} />
                      )}
                    </button>
                  </div>
                </div>
                <Button
                  className="mt-8"
                  styles={{ width: "20rem" }}
                  onClick={() => {
                    onLogin(username, password);
                  }}
                >
                  Login
                </Button>
                <div>
                  <p className="text-center text-sm mt-2 font-medium">
                    An <span className="color-primary ">ONO</span> Product
                  </p>
                </div>
              </div>
            </div>

            {/* <div
              className="fixed left-0"
              style={{ width: width, bottom: "30vh" }}
            >
              <img src={logo} className="w-24 ml-auto mr-auto" />
            </div> */}
          </div>
          <div
            className="absolute bottom-0 left-0 bg-cover w-full  sm:h-60 bg-no-repeat "
            style={{
              height: "13rem",
              backgroundImage: `url(${Illustration})`,
            }}
          ></div>
        </div>
      </div>
    </>
  );
};
export default Login;
