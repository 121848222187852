import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import DeviceLayout from "./layout";
import AuthLayout from "./layout/auth-layout";
import PageLayout from "./layout/page-layout";
import "./App.css";

const Home = lazy(() => import("./pages/home"));
const TradeDetail = lazy(() => import("./pages/trade-detail"));

const Myprofile = lazy(() => import("./pages/my-profile"));
const Customer = lazy(() => import("./pages/customer/Customer"));
const AllDetails = lazy(
  () => import("./pages/customer/CustomerDetails/AllDetails")
);

const Loans = lazy(() => import("./pages/loans"));

const ApplicationView = lazy(
  () => import("./pages/loans/Applications/application-view")
);
const BorrowerDetails = lazy(() => import("./pages/customer/BorrowerDetails"));
const BeneficiaryDetails = lazy(
  () => import("./pages/customer/BorrowerDetails/benificiary-details")
);
const SupplyPostView = lazy(() => import("./pages/supply/supply-post-details"));
const DemandPostView = lazy(() => import("./pages/demand/demand-post-details"));
const PostDetails = lazy(() => import("./pages/supply/post-details"));

const Reports = lazy(() => import("./pages/reports"));
const DebitCreditReports = lazy(
  () => import("./pages/reports/debitCreditReports")
);
const OpenTradesPaymentReports = lazy(
  () => import("./pages/reports/OpenTradesPaymentReports")
);
const AllTradeDetailReports = lazy(
  () => import("./pages/reports/AllTradeDetailReports")
);
const ExcessPaymentReport = lazy(
  () => import("./pages/reports/ExcessPaymentReport")
);
const CustomersV2 = lazy(() => import("./pages/customersV2"));
const CustomerDetails = lazy(
  () => import("./pages/customersV2/customerDetails")
);
function App() {
  return (
    <DeviceLayout>
      <AuthLayout>
        <PageLayout>
          <Suspense fallback={<>Loading...</>}>
            <Routes>
              {/* <Route path="/" element={<Customer />} /> */}
              <Route path="/" element={<CustomersV2 />} />
              <Route path="/customers" element={<CustomersV2 />} />
              <Route
                path="/customer-details/:mosId"
                element={<CustomerDetails />}
              />

              <Route
                path="/trade-detail/:traderId/:tradeId"
                element={<TradeDetail />}
              />
              <Route path="/my-profile" element={<Myprofile />} />
              {/* <Route path="/customers" element={<Customer />} /> */}
              <Route path="/allDetails/:traderId" element={<AllDetails />} />
              <Route path="/trades" element={<Home />} />
              <Route path="/loans" element={<Loans />} />
              <Route
                path="/borrower-detail/:appId/:type"
                element={<BorrowerDetails />}
              />
              {/* <Route path="/traders-view" element={<TraderDetailsView />} /> */}

              <Route
                path="/beneficiary-detail/:appId/:partyAppId/:loanAccNo/:type"
                element={<BeneficiaryDetails />}
              />
              <Route
                path="/application-view/:applicationId"
                element={<ApplicationView />}
              />
              <Route
                path="/supply-details/:postId"
                element={<SupplyPostView />}
              />
              <Route
                path="/demand-details/:postId"
                element={<DemandPostView />}
              />
              <Route path="/post-details" element={<PostDetails />} />
              <Route path="/reports" element={<Reports />}>
                <Route
                  path="/reports/debit-credit-reports"
                  element={<DebitCreditReports />}
                />
                <Route
                  path="/reports/payment-receipts"
                  element={<OpenTradesPaymentReports />}
                />
                <Route
                  path="/reports/allTradeDetail-Reports"
                  element={<AllTradeDetailReports />}
                />
                <Route
                  path="/reports/ExcessPayment-Reports"
                  element={<ExcessPaymentReport />}
                />
              </Route>
              {/* <Route path="/allDetails/:traderId/:tradeId" element={< TradeDetail/>} /> */}
            </Routes>
          </Suspense>
        </PageLayout>
      </AuthLayout>
    </DeviceLayout>
  );
}

export default App;
