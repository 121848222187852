import React from "react";
import { Avatar } from "@mui/material";

interface UserAvatarProps {
  name: string;
}

const getRandomColor = (name: string): string => {
  const colors = [
    "#F44336",
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#03A9F4",
    "#00BCD4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#795548",
    "#9E9E9E",
    "#607D8B",
  ];
  const index = name.charCodeAt(0) % colors.length;
  return colors[index];
};

const UserAvatar: React.FC<UserAvatarProps> = ({ name }) => {
  const firstLetter = name ? name.charAt(0).toUpperCase() : "?";
  const backgroundColor = getRandomColor(name);

  return (
    <Avatar
      sx={{ bgcolor: backgroundColor, width: 35, height: 35, fontSize: 18 }}
    >
      {firstLetter}
    </Avatar>
  );
};

export default UserAvatar;
