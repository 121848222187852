import React from "react";
import { Document, Page, View, StyleSheet } from "@react-pdf/renderer";
import FinalTaxInvoiceDoc from "./finalTaxInvoice";
import SrcPaymentReceiptDoc from "./srcPayoutPaymentReceipt";

const styles = StyleSheet.create({
  page: {
    paddingTop: 80, // Adjust to leave space for the header
    paddingBottom: 60, // Adjust to leave space for the footer
    paddingHorizontal: 40,
    fontFamily: "Roboto",
    height: "100%",
  },
});

const PaymentRecDocs = ({ details, type, status, seqId }) =>
  status == "single" ? (
    <Document>
      <Page size="A4" style={styles.page}>
        <SrcPaymentReceiptDoc details={details} type={type} seqId={seqId} />
      </Page>
    </Document>
  ) : (
    <Document></Document>
  );

export default PaymentRecDocs;
