import { FC, useState } from "react";
import click from "../../../assets/images/click.svg";
import trade from "../../../assets/images/trade.svg";
import cash_icon from "../../../assets/images/cash_icon.svg";
import { getProductIcons } from "../../../helpers/get-currency-number";
const TabsList: FC<{
  data: Array<{ label: string; value: string; icon?: boolean }>;
  onChange: (tab: { label: string; value: string }, i: number) => void;
  activeTab?: string;
  className?: string;
}> = ({ data, onChange, activeTab, className }) => {
  const handleClick = (tab: { label: string; value: string }, i: number) => {
    onChange(tab, i);
  };
  return (
    <>
      <ul
        className={`flex overflow-x-auto md:overflow-x-none  gap-4 ${className}`}
      >
        {data.map((tab, i) => (
          <li
            key={`tab-${i}`}
            className={`cursor-pointer whitespace-nowrap px-2 py-2 fs-12 rounded-t-lg   font-bold ${
              activeTab === tab.value
                ? "color-primary active_border"
                : " color-inactive"
            }`}
            onClick={() => handleClick(tab, i)}
          >
            <div className={`flex ${tab.icon ? "gap-1" : ""} items-center`}>
              {tab.icon ? (
                <img
                  src={getProductIcons(tab.value.toUpperCase())}
                  className="w-4"
                />
              ) : (
                ""
              )}

              {tab.label}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};
export default TabsList;
