import moment from "moment/moment";
import date_icon from "../../../assets/images/date_icon.svg";
import { CSSProperties, FC, useState } from "react";
import Popover from "../../../components/ui/popover";
import Calender from "../../../components/commons/calender";
import classes from "./style.module.scss";
type TSelectDate = {
  className?: string;
  styles?: CSSProperties;
  onChange: (date: string) => void;
  selectedDate: string;
  maxDate?: string;
  statusStyle?: boolean;
  statusStyleSMall?: boolean;
};
const SelectTradeDate: FC<TSelectDate> = ({
  onChange,
  selectedDate,
  maxDate,
  statusStyle,
  styles,
  statusStyleSMall,
}) => {
  const [calenderOpen, setCalenderOpen] = useState(false);
  /** Function to handle date selection */
  const handleDateSelection = (_date: string) => {
    onChange(moment(_date, "YYYY-MMM-DD").format("YYYY-MM-DD"));
    setCalenderOpen(false);
  };
  return (
    <div className="relative items-center flex justify-center shrink-0">
      <div
        className={`flex bg-white items-center w-full fs-14 gap-2 mt_8 px-2 py-2 border br-10 text-sm cursor-pointer justify-between ${
          statusStyle ? classes.heightInput : ""
        } ${statusStyleSMall ? classes.heightInputSmall : ""}`}
        onClick={() => {
          setCalenderOpen(!calenderOpen);
        }}
        style={styles}
      >
        <span className="color-blue">
          {" "}
          {selectedDate
            ? moment(selectedDate).format("DD-MMM-YYYY")
            : "Select Date"}
        </span>
        <img src={date_icon} alt="icon" className="w-4 h-4" />
      </div>
      <Popover
        style={{
          width: "18rem",
          height: "20rem",
          marginTop: "1rem",
        }}
        open={calenderOpen}
        onClose={() => {
          setCalenderOpen(false);
        }}
      >
        {/*  @ts-ignore */}

        <Calender
          mode="single"
          currentDate={selectedDate}
          onChange={(data: any) => {
            handleDateSelection(data.date);
          }}
          maxDate={maxDate ?? moment().format("YYYY-MM-DD")}
        />
      </Popover>
    </div>
  );
};

export default SelectTradeDate;
